import {Action, configureStore, createAsyncThunk, createSlice, PayloadAction, ThunkAction} from "@reduxjs/toolkit";
import {connectors} from "../eth/connectors";
import {useDispatch} from "react-redux";
import {useWeb3React} from "@web3-react/core";
import {useEffect} from "react";

interface CommonSlice {
    isConnectorModalOpen?: boolean;
    isTermsPopupOpen?: boolean;
}

const initialState: CommonSlice = {}

const commonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        setConnectorModalOpen: (state, action: PayloadAction<boolean>) => {
            state.isConnectorModalOpen = action.payload;
        },
        setTermsPopupOpen: (state, action: PayloadAction<boolean>) => {
            state.isTermsPopupOpen = action.payload;
        },
    }
});


export const store = configureStore({
    reducer: {
        common: commonSlice.reducer
    }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
export const useAppDispatch: () => AppDispatch = useDispatch;

export const setConnectorModalOpen = commonSlice.actions.setConnectorModalOpen;
export const isConnectorModalOpenSelector = (state: RootState) => state.common.isConnectorModalOpen;

export const setTermsPopupOpen = commonSlice.actions.setTermsPopupOpen;
export const isTermsPopupOpenSelector = (state: RootState) => state.common.isTermsPopupOpen;

export const useStoreInitialization = () => {
    const {activate} = useWeb3React();

    useEffect(()=>{
        const provider = window.localStorage.getItem("provider");
        if (!provider) return;
        // @ts-ignore
        activate(connectors[provider]);
    }, []);
};

export const saveProvider = createAsyncThunk(
    "saveProvider",
    (name: string) => {
        window.localStorage.setItem("provider", name);
    }
)