import {FormattedMessage} from "react-intl";
import {DefaultButton} from "../buttons/DefaultButton";
import {Popup} from "../common/Popup";


export function TermsPopup(props: {onClose: ()=>void}) {
    return <Popup onClose={props.onClose}>
        <div className="text-dark text-center text-3xl font-bold">
            <FormattedMessage id="t_header" />
        </div>
        <div className="text-light-dark text-center font-sofia-pro mt-3 font-bold">
            <FormattedMessage id="t_subheader" />
        </div>
        <div className="text-light-dark font-sofia-pro flex flex-col gap-2 mt-3">
            <div className="flex gap-1">
                <div>1</div>
                <div>
                    <FormattedMessage id="t_1" />
                </div>
            </div>
            <div className="flex gap-1">
                <div>2</div>
                <div>
                    <FormattedMessage id="t_2" />
                </div>
            </div>
            <div className="flex gap-1 ml-8">
                <div>a.</div>
                <div>
                    <FormattedMessage id="t_2_a" />
                </div>
            </div>
            <div className="flex gap-1 ml-8">
                <div>b.</div>
                <div>
                    <FormattedMessage id="t_2_b" />
                </div>
            </div>
            <div className="flex gap-1">
                <div>3</div>
                <div>
                    <FormattedMessage id="t_3"
                                      values={{
                                          link: <a href={"https://feldschloesschen.ch/Teilnahmebedingungen-wettbewerbe"} className={"text-active"}>
                                              <FormattedMessage id={"t_3_link"} />
                                          </a>
                                      }}
                    />
                </div>
            </div>
            <div className="flex gap-1">
                <div>4</div>
                <div>
                    <FormattedMessage id="t_4" />
                </div>
            </div>
            <div className="flex gap-1">
                <div>5</div>
                <div>
                    <FormattedMessage id="t_5" />
                </div>
            </div>
            <div className="flex gap-1 ml-8">
                <div>a.</div>
                <div>
                    <FormattedMessage id="t_5_a"
                                      values={{
                                          bold: <b><FormattedMessage id={"t_5_a_bold"} /></b>
                                      }}
                    />
                </div>
            </div>
            <div className="flex gap-1 ml-8">
                <div>b.</div>
                <div>
                    <FormattedMessage id="t_5_b"
                                      values={{
                                          bold: <b><FormattedMessage id={"t_5_b_bold"}/></b>
                                      }}
                    />
                </div>
            </div>
            <div className="flex gap-1">
                <div>6</div>
                <div>
                    <FormattedMessage id="t_6" />
                </div>
            </div>
            <div className="flex gap-1">
                <div>7</div>
                <div>
                    <FormattedMessage id="t_7" />
                </div>
            </div>
            <div className="mt-6 flex justify-center">
                <DefaultButton className="w-72" onClick={()=>props.onClose()}>
                    <FormattedMessage id="Alles klar!" />
                </DefaultButton>
            </div>
        </div>
    </Popup>
}
