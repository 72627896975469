import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";

const mainnetUrl = "https://mainnet.infura.io/v3/9cec47d8e5754f75a209ecc2fa192627";
const goerliUrl = "https://goerli.infura.io/v3/9cec47d8e5754f75a209ecc2fa192627";

const injected = new InjectedConnector({
    supportedChainIds: [1, 4, 5]
});

const walletconnect = new WalletConnectConnector({
    rpc: {
        1: mainnetUrl,
        5: goerliUrl
    },
    bridge: "https://bridge.walletconnect.org",
    qrcode: true
});

const walletlink = new WalletLinkConnector({
    url: goerliUrl,
    supportedChainIds: [1, 5],
    appName: "Castle Heroes"
});

export const connectors = {
    injected: injected,
    walletConnect: walletconnect,
    coinbaseWallet: walletlink
};

export type ConnProvider = keyof typeof connectors;
