import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {ethers} from "ethers";
import {Web3ReactProvider} from "@web3-react/core";
import {DEFAULT_LANGUAGE, getInitialLanguage, Language, TranslationProvider} from './app/translation';
import "./index.css";
import { Provider } from 'react-redux'
import {store} from "./app/store";

// eslint-disable-next-line
const consoleError = console.error.bind(console);
// eslint-disable-next-line
console.error = (message, ...args) => {
  if (
    typeof message === 'string' &&
    message.startsWith('[React Intl] Missing message:')
  ) {
    return;
  }
  consoleError(message, ...args);
};

export const CommonContext = React.createContext({} as {
  language: Language,
  setLanguage: (lang: Language) => void
});

const getLibrary = (provider: any) => {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = 8000;
  return library;
};

const Wrapper = () => {
  const [language, setLanguage] = useState<Language>(getInitialLanguage());

  return <CommonContext.Provider value={{language, setLanguage}}>
    <Provider store={store}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <TranslationProvider>
          <App/>
        </TranslationProvider>
      </Web3ReactProvider>
    </Provider>
  </CommonContext.Provider>
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Wrapper />
  </React.StrictMode>
);