import React, { useState} from "react";
import { Header } from "./components/common/Header";
import { HowToPopup } from "./components/sections/HowToPopup";
import { MintSection } from "./components/sections/mint/MintSection";
import "./App.sass";
import { useSelector} from "react-redux";
import {
    isTermsPopupOpenSelector,
    setTermsPopupOpen,
    useAppDispatch,
    useStoreInitialization
} from "./app/store";
import {TermsPopup} from "./components/sections/TermsPopup";
import {AgeGate} from "./agegate/AgeGate";

export type ScrollablePoint = "story" | "utility" | "faq";
export type OnNavHandler = (point: ScrollablePoint) => void;


function App() {
    const [howToOpen, setHowToOpen] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const isTermsOpen = useSelector(isTermsPopupOpenSelector);

    useStoreInitialization();

    return <div>
        <AgeGate/>
        {isTermsOpen&&<TermsPopup onClose={()=>dispatch(setTermsPopupOpen(false))} />}
        {howToOpen&&<HowToPopup onClose={() => setHowToOpen(false)}/>}  
        <Header onNav={(point: ScrollablePoint) => {}}/>
        <MintSection />
    </div>
}

export default App;
