import { useWeb3React } from "@web3-react/core";
import React, {ReactElement, useEffect, useState} from "react";
import Web3 from "web3";
import { CONTRACT_ABI } from "../../../abi";
import { connectors, ConnProvider } from "../../../eth/connectors";
import { shortenAccountNumber, switchNetwork } from "../../../eth/ethUtils";
import { CONTRACT_ADDRESS } from "../../../eth_config";
import { DefaultButton } from "../../buttons/DefaultButton";
import { ConnectorModal } from "../../eth/ConnectorModal";
import PresaleUrl from "../../../assets/mint/presale.png";
import HeroesUrl from "../../../assets/mint/heroes.png";
import NFTPreviewUrl from "../../../assets/mint/nft-preview.gif";
import { FormattedMessage } from "react-intl";
import {
    isConnectorModalOpenSelector, saveProvider,
    setConnectorModalOpen,
    setTermsPopupOpen,
    useAppDispatch
} from "../../../app/store";
import {useDispatch, useSelector} from "react-redux";
import {ReactComponent as CheckboxEmpty} from "../../../assets/mint/checkbox-empty.svg";
import {ReactComponent as CheckboxChecked} from "../../../assets/mint/checkbox-checked.svg";
import {ReactComponent as Loading} from "../../../assets/mint/loading.svg";
import {Popup} from "../../common/Popup";
import {DISCORD_URL, useHomeUrl} from "../../../config";

const minGas = 260000;
const maxGas = 350000;

const NOT_IN_WHITELIST = "NOT_IN_WHITELIST";

export function MintSection() {
    const HOME_URL = useHomeUrl();

    const [isTermsChecked, setTermsChecked] = useState<boolean>(false);
    const [clicked, setClicked] = useState<boolean>(false);
    const [minting, setMinting] = useState<boolean>(false);
    const [transactionHash, setTransactionHash] = useState<string>("");

    const dispatch = useAppDispatch();
    const isWalletSelectorOpen = useSelector(isConnectorModalOpenSelector);

    const [error, setError] = useState<ReactElement|string|null>(null);
    const [success, setSuccess] = useState<boolean>(false);
    const [tokenId, setTokenId] = useState<string>("");

    const [loading, setLoading] = useState<boolean>(false);
    const [minted, setMinted] = useState<number>(0);

    const {
        library,
        chainId,
        account,
        activate,
        deactivate,
    } = useWeb3React();


    const getWeb3 = () => {
        return new Web3(library.provider);
    }
    const getContract = () => {
        const web3 = getWeb3();
        return new web3.eth.Contract(CONTRACT_ABI as any, CONTRACT_ADDRESS);
    }

    const currentEnv = chainId === 1 ? "https://etherscan.io/" : "https://goerli.etherscan.io/";

    const onProviderSelected = async (provider: ConnProvider) => {
        const providerConn = connectors[provider];
        dispatch(saveProvider(provider));
        await deactivate();
        try {
            console.log('activate', providerConn);
            const activationResult = await activate(providerConn);
            console.log('activation result', activationResult);
        } catch (e) {
            console.log('activation error', e);
        }
    };
    
    useEffect(()=>{
        if (!account) {
            return;
        }
        if (!library) return;
        if (chainId != 5) {
            switchNetwork(library, 5);
        }
        const contract = getContract();
        setLoading(true);
        (async ()=>{
            try {
                const balance = Number(await contract.methods.balanceOf(account).call());
                if (!isNaN(balance)) {
                    setMinted(balance);
                    if (balance > 1) {
                        setSuccess(true);
                        setMinting(true);
                    }
                }
            } catch (e: any) {
                console.log(`error while balance request`);
                console.log(e);
            } finally {
                setLoading(false);
            }
        })();
    }, [account, chainId, library]);

    const onError = (e: any) => {
        if (typeof e === "string") {
            if (e === NOT_IN_WHITELIST) {
                setError(e);
            } else {
                setError(<FormattedMessage id={e} />);
            }
        } else if (e.message) {
            if (e.message.includes("User denied")) {
                setError(null);
            } else {
                setError(<div>{e.message}</div>);
            }
        } else {
            setError(<div>{e.toString()}</div>);
        }
        setMinting(false);
    };

    const mint = async () => {
        setClicked(true);
        if (!isTermsChecked || minting) return;
        if (transactionHash) return;

        setError(null);
        const proof = await fetch("api/?action=get_proof", {
            method: "POST",
            body: JSON.stringify({"address": account})
        }).then(r=>r.json());
        if (proof.length == 0) {
            onError(NOT_IN_WHITELIST);
            return;
        }
        const contract = getContract();
        const web3 = getWeb3();

        setMinting(true);
        try {
            let gl = maxGas;
            const amount = 1;
            try {
                const egl = await contract.methods.privateMint(proof, 1).estimateGas({
                    from: account,
                    to: CONTRACT_ADDRESS,
                    value: 0
                });
                console.log("Gas Limit estimate: " + egl);
                gl = egl > 0 && egl < (maxGas * amount) ? egl : (maxGas * amount);
                console.log("Gas limit set after sanity check: " + gl);
            }
            catch(err) {
                console.log(`cannot estimate gas: ${err}`);
            }
            let txHash = "";
            
            contract.methods.privateMint(proof, 1)
                .send({
                    gasLimit: gl,
                    from: account,
                    to: CONTRACT_ADDRESS,
                    value: 0,
                    maxPriorityFeePerGas: null,
                    maxFeePerGas: null
                })
                .on('error', function (error: any) {
                    console.log("on error");
                    console.log(error);
                    
                    onError(error);
                })
                .on('transactionHash', function (transactionHash: string) {
                    console.log("on transactionHash");
                    console.log(transactionHash);
                    txHash = transactionHash;

                    setTransactionHash(txHash);
                })
                .on('receipt', function (receipt: any) {
                    console.log("on receipt");
                    console.log(receipt);
                    const tokenId = receipt.events.Transfer.returnValues.tokenId;
                    setTokenId(tokenId);
                })
                .on('confirmation', function (confirmationNumber: any, receipt: any) {
                    console.log(confirmationNumber, receipt);
                })
                .then((res: any) => {
                    console.log(res);
                    setSuccess(true);
                })
                .catch((err: any) => {
                    console.log(`catch`);
                    console.log(err);
                    onError(err);

                    (async ()=>{
                        const tx = await web3.eth.getTransaction(txHash);
                        // @ts-ignore
                        let result = await web3.eth.call(tx, tx.blockNumber);

                        console.log(`err call result`);
                        console.log(result);

                        result = result.startsWith('0x') ? result : `0x${result}`

                        if (result && result.substr(138)) {
                            const reason = web3.utils.toAscii(result.substr(138))
                            console.log('Revert reason:', reason)
                        } else {
                            console.log('Cannot get reason - No return value')
                        }
                    })();
                });

        } catch (e: any) {
            console.error(e);
            onError(e);
        }
        
    };

    return <div className="flex justify-center">
        {!account && <div className="m-6 md:m-0 mt-16 md:mt-32 bg-white p-6 md:p-10 md:py-20 rounded-2xl flex flex-col md:flex-row items-center gap-4 md:gap-20
            w-[800px] max-w-full"
        >
            <div className="w-full md:w-auto md:shrink-0 md:-ml-20">
                <img src={PresaleUrl} className="w-full md:w-64"/>
            </div>
            <div>
                {isWalletSelectorOpen && <ConnectorModal
                    onClose={() => dispatch(setConnectorModalOpen(false))}
                    onProviderSelected={onProviderSelected}
                />}

                <div>
                    <div className="text-active font-klint-pro font-bold">
                        <FormattedMessage id="Hol dir deinen Castle Hero!"/>
                    </div>
                    <div className="text-dark font-bold font-klint-pro text-headline-3 mt-2">
                        <FormattedMessage id="Werde Teil der Community!"/>
                    </div>
                    <div className="text-light-dark mt-2 font-klint-pro">
                        <FormattedMessage
                            id="Dann verbinde jetzt deinen Wallet um dir deinen Castle Hero zu sichern. Du zahlst lediglich die GAS Gebühren."/>
                    </div>
                    <DefaultButton onClick={() => dispatch(setConnectorModalOpen(true))}
                                   className="mt-5 px-10 w-full md:w-auto">
                        <FormattedMessage id="Wallet verbinden"/>
                    </DefaultButton>
                </div>
            </div>
        </div>
        }
        {account&&!success&&<div className="m-6 md:m-0 mt-16 md:mt-32 bg-white p-10 rounded-2xl w-[550px] max-w-full">
            <div className="flex justify-center">
                <img src={HeroesUrl} className={"w-full md:w-[350px]"}/>
            </div>
            <div className={"text-dark text-headline-3 text-center mt-3"}>
                <FormattedMessage id={"Minte deinen Castle und werde Teil der Community"} />
            </div>
            <div className={"text-active font-klint-pro mt-3 text-center"}>
                <FormattedMessage id={"Verbunden mit {address}"} values={{
                    address: shortenAccountNumber(account)
                }}/>
            </div>
            {!minting&&<div className={"text-light-dark font-klint-pro mt-3 text-center"}>
                <FormattedMessage id={"Du kannst dir maximal einen Castle Hero pro Wallet sichern."} />
            </div>}
            {minting&&<div className="text-red font-klint-pro text-center mt-3">
                <FormattedMessage id={"Schliesse nicht das Fenster und warte bis du die Bestätigung deines Wallets erhalten hast."}/>
            </div>}
            {!minting&&<div className={"font-klint-pro font-bold mt-3 flex gap-3 items-center justify-center cursor-pointer select-none"}
                 onClick={()=>setTermsChecked(v=>!v)}
            >
                {isTermsChecked&&<CheckboxChecked className="cursor-pointer w-6 h-6" />}
                {!isTermsChecked&&<CheckboxEmpty className="cursor-pointer w-6 h-6" />}
                <div className={"shrink-0"}>
                    <FormattedMessage id={"Hiermit stimme ich den {terms} zu"}
                        values={{
                            terms: <span className={"underline cursor-pointer"}
                                         onClick={()=>dispatch(setTermsPopupOpen(true))}
                            ><FormattedMessage id={"Teilnahmebedinungen"} /></span>
                        }}
                    />
                </div>
            </div>}
            <div className="mt-8">
                {!loading&&!minting&&
                <DefaultButton className={`h-[72px] py-0 w-full ${isTermsChecked?'bg-red hover:bg-red active:bg-red':'bg-light-dark hover:bg-light-dark active:bg-light-dark'}`}
                               onClick={mint}
                >
                    <FormattedMessage id={"Jetzt deinen Castle Hero Minten"} />
                </DefaultButton>}

                {(loading||minting&&!success&&!error)&&
                <DefaultButton className={`h-[72px] py-0 w-full bg-red hover:bg-red active text-center flex justify-center items-center`} rewriteBg={true}>
                    <Loading className={"w-8 h-8 animate-spin"}/>
                </DefaultButton>
                }
            </div>
            <div className="mt-4 text-center text-red font-bold ">
                {!isTermsChecked&&clicked&& <FormattedMessage id={"Bitte stimme noch den Teilnahmebedingungen zu "} />}
            </div>
            {error&&<Popup onClose={()=>setError(null)} width={"400px"}>
                <div className={"text-dark text-headline-3 text-center font-klint-pro"}>Da ging leider etwas schief!</div>
                <div className={"mt-6 text-light-dark text-center font-klint-pro text-lg"}>
                    {error==NOT_IN_WHITELIST ?
                        <div>
                            <FormattedMessage id={"Du befindest dich nicht auf der Whitelist"} />
                        </div>
                        :
                        <div>
                            <FormattedMessage id={"Bitte versuche es erneut oder überprüfe ob du genug Guthaben zur Transkation in deinem Wallet hast"} />
                        </div>
                    }
                </div>
                <div className="flex justify-center mt-6">
                    <DefaultButton onClick={()=>setError(null)}
                        rewriteBg={true}
                        className={"text-active border-2 border-active py-4"}
                    >
                        Alles klar
                    </DefaultButton>
                </div>
            </Popup>}
        </div>}
        {account&&success&&<div className="m-6 md:m-0 mt-16 md:mt-32 bg-white p-10 rounded-2xl w-[550px] max-w-full">
            <div className="flex justify-center">
                <img src={NFTPreviewUrl} className={"w-full md:w-[190px] rounded-lg"}/>
            </div>
            {!transactionHash&&<>
                <div className={"text-red text-headline-3 text-center mt-3"}>
                    <FormattedMessage id={"In deinem Wallet hat nur Platz für einen Castle Hero…"}
                    />
                </div>
                <div className={"text-light-dark text-center mt-3 text-lg font-klint-pro"}>
                    <FormattedMessage id={"... und wir haben bereits einen in deinem Wallet gefunden. Sieht doch mal nach!"} />
                </div>
                <div className="flex justify-center mt-3">
                    <a href={HOME_URL} className={"block w-full"}>
                        <DefaultButton className="w-full">
                            Zurück
                        </DefaultButton>
                    </a>
                </div>
            </>}
            {transactionHash&&<>
                <div className={"text-dark text-headline-3 text-center mt-3"}>
                    <FormattedMessage id={"Hurra!{br}Castle Hero {number} ist in dein Wallet gesprungen!"}
                    values={{
                        br: <br/>,
                        number: tokenId?`#${tokenId}`:''
                    }}
                    />
                </div>
                <div className={"text-light-dark text-center mt-3 text-lg font-klint-pro"}>
                    <FormattedMessage id={"Joine doch gleich unserem Community Discord und warte gespannt auf den Reveal."} />
                </div>
                <div className="flex justify-center mt-3">
                    <a href={DISCORD_URL} className={"block w-full"}>
                        <DefaultButton className="w-full">
                            <FormattedMessage id={"Auf zu Discord!"} />
                        </DefaultButton>
                    </a>
                </div>
                <div className="flex justify-center mt-3">
                    <a target="_blank" href={`${currentEnv}/tx/${transactionHash}`} className={"block w-full"}>
                        <DefaultButton className="w-full text-active border-2 border-active"
                            rewriteBg={true}
                        >
                            <FormattedMessage id={"Transaktion auf Etherscan öffnen"}/>
                        </DefaultButton>
                    </a>
                </div>
            </>}
        </div>}
    </div>;
}
